<template>
    <div id="skripsi" class="mcontainer">
        <loading :loading="loading" spinner-class="justify-center">
            <div>
                <div class="md:flex justify-between relative md:mb-4 mb-3">
                    <div class="flex-1">
                        <h2 class="text-lg font-semibold color-primary">Skripsi</h2>
                        <p>{{ data.title }}</p>
                        <br>
                    </div>
                </div>

                <div class="horizontal-menu mb-4">
                    <router-link :to="{ name: 'InformasiSkripsi' }">Informasi Skripsi</router-link>
                    <router-link v-if="!data.id" :to="{ name: 'PengajuanSkripsi' }">Pengajuan Skripsi</router-link>
                    <router-link v-if="data.id" :to="{ name: 'PengajuanBimbingan' }">Pengajuan Bimbingan</router-link>
                    <router-link :to="{ name: 'HistorySkripsi' }">History</router-link>
                    <router-link :to="{ name: 'PengajuanSidangSkripsi' }">Pengajuan Sidang Skripsi</router-link>
                </div>

                <transition name="slide-fade" mode="out-in">
                    <router-view class="mt-4" />
                </transition>
            </div>
        </loading>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Skripsi',
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' }),
            ...mapState('auth', { user: 'user' })
        },
        data () {
            return {
                breadcrumbs: [
                    {
                        route: 'PengajuanSkripsi',
                        label: 'Pengajuan Skripsi',
                        active: true
                    },
                    {
                        route: 'DetailBimbingan',
                        label: 'Detail Bimbingan'
                    }
                ],
                showDetail: false
            }
        },
        methods: {
            ...mapActions('mentors', { fetchMentors: 'fetch' }),
            ...mapActions('skripsi', { createSkripsi: 'create', fetchSkripsi: 'fetch' })
        },
        mounted () {
            this.fetchSkripsi()
                .then((res) => {
                    if (res.length < 1) this.$router.push({ name: 'PengajuanSkripsi' })
                })
        }
    }
</script>
